import React, { useCallback, useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { makeStyles } from '@material-ui/core';

import { Button, ButtonProps } from '@Components/common';
import { useAppLayoutContext, useNotificationsContext, useUserContext } from '@Contexts';
import { Project, ProjectStatusEnum, SelectionStatusEnum, UserTypeEnum } from '@Types';
import { useRouter } from '@Helpers/useRouter';
import { useCreateSelection, useFundInvitesProject, useGetFundsList } from '@Hooks/Api';
import styleVariables from '@Styles/_variables.module.scss';

enum CTA_STATES_ENUM {
  PROJECT_GROUP_MANAGER_WITH_MATCHES = 'PROJECT_GROUP_MANAGER_WITH_MATCHES',
  PROJECT_GROUP_MANAGER_WITHOUT_MATCHES = 'PROJECT_GROUP_MANAGER_WITHOUT_MATCHES',
  PROJECT_GROUP_NONOWNER = 'PROJECT_GROUP_NONOWNER',
  PROJECT_FUNDER_NO_FUNDS = 'PROJECT_FUNDER_NO_FUNDS',
  PROJECT_FUNDER_UNMATCHED = 'PROJECT_FUNDER_UNMATCHED',
  PROJECT_FUNDER_SINGLE_MATCH_MATCHED = 'PROJECT_FUNDER_SINGLE_MATCH_MATCHED',
  PROJECT_FUNDER_SINGLE_MATCH_SHORTLISTED = 'PROJECT_FUNDER_SINGLE_MATCH_SHORTLISTED',
  PROJECT_FUNDER_SINGLE_MATCH_INVITED = 'PROJECT_FUNDER_SINGLE_MATCH_INVITED',
  PROJECT_FUNDER_MULTIPLE_MATCH = 'PROJECT_FUNDER_MULTIPLE_MATCH',
  PROJECT_UNLOGGED_USER = 'PROJECT_UNLOGGED_USER',
}

interface CtaButtonProps {
  headerRef: React.MutableRefObject<React.ReactNode>;
  project?: boolean;
  fundId?: number;
  projectId?: number;
  userCanManage: boolean;
  matchesCount?: number;
  selectionId?: number;
  selectionStatus?: SelectionStatusEnum;
  updateProjectData?: (projectId: number) => Promise<AxiosResponse<Project>>;
  status?: string;
  userCanEdit: boolean;
}

export const CtaButton: React.FC<CtaButtonProps> = ({
  headerRef,
  project,
  fundId,
  projectId,
  userCanManage,
  matchesCount,
  selectionId,
  selectionStatus,
  updateProjectData,
  status,
  userCanEdit,
}) => {
  const [isBtnInView, setIsBtnInView] = useState(true);
  const [ctaState, setCtaState] = useState<CTA_STATES_ENUM>();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { setHeaderButtons } = useAppLayoutContext();
  const { t: tProject } = useTranslation('project');
  const { userData } = useUserContext();
  const { push } = useRouter();
  const [createSelection] = useCreateSelection();
  const [fundInvitesProject] = useFundInvitesProject();
  const [
    getFundsList,
    {
      paginatedData: { page: fundsList },
    },
  ] = useGetFundsList();
  const { error: errorNotification, success: successNotification } = useNotificationsContext();

  const isBtnPrimary =
    ctaState !== CTA_STATES_ENUM.PROJECT_GROUP_MANAGER_WITHOUT_MATCHES &&
    ctaState !== CTA_STATES_ENUM.PROJECT_GROUP_MANAGER_WITH_MATCHES;

  const handleScroll = useCallback(() => {
    const { bottom } = (headerRef.current as HTMLElement)?.getBoundingClientRect() || {};

    if (!bottom) return;

    if (bottom < 80 && isBtnInView) {
      setIsBtnInView(false);
    } else if (bottom >= 80 && !isBtnInView) {
      setIsBtnInView(true);
    }
  }, [headerRef, isBtnInView]);

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (userData && userData.user_type === UserTypeEnum.FUNDER) {
      getFundsList(userData.organization_id);
    }
  }, [userData, fundId, getFundsList]);

  useEffect(() => {
    if (userData) {
      if (userData.user_type === UserTypeEnum.GROUP) {
        if (userCanManage) {
          if (matchesCount === 0 && userCanEdit) {
            setCtaState(CTA_STATES_ENUM.PROJECT_GROUP_MANAGER_WITHOUT_MATCHES);
          } else if (matchesCount && matchesCount > 0) {
            setCtaState(CTA_STATES_ENUM.PROJECT_GROUP_MANAGER_WITH_MATCHES);
          }
        } else {
          setCtaState(CTA_STATES_ENUM.PROJECT_GROUP_NONOWNER);
        }
      } else if (userData.user_type === UserTypeEnum.FUNDER) {
        if (
          fundsList &&
          fundsList.length === 0 &&
          status === tProject(`status.${ProjectStatusEnum.PUBLISHED}`)
        ) {
          setCtaState(CTA_STATES_ENUM.PROJECT_FUNDER_NO_FUNDS);
        } else if (matchesCount === 0) {
          setCtaState(CTA_STATES_ENUM.PROJECT_FUNDER_UNMATCHED);
        } else if (matchesCount === 1) {
          if (selectionId) {
            if (selectionStatus === SelectionStatusEnum.SHORTLISTED) {
              setCtaState(CTA_STATES_ENUM.PROJECT_FUNDER_SINGLE_MATCH_SHORTLISTED);
            } else {
              setCtaState(CTA_STATES_ENUM.PROJECT_FUNDER_SINGLE_MATCH_INVITED);
            }
          } else if (status === tProject(`status.${ProjectStatusEnum.PUBLISHED}`)) {
            setCtaState(CTA_STATES_ENUM.PROJECT_FUNDER_SINGLE_MATCH_MATCHED);
          }
        } else if (matchesCount && matchesCount > 1) {
          setCtaState(CTA_STATES_ENUM.PROJECT_FUNDER_MULTIPLE_MATCH);
        }
      }
    }
  }, [
    userCanManage,
    matchesCount,
    userData,
    project,
    selectionId,
    selectionStatus,
    status,
    tProject,
    fundsList,
  ]);

  const mapCtaAttributes = useMemo(
    () => ({
      [CTA_STATES_ENUM.PROJECT_GROUP_MANAGER_WITHOUT_MATCHES]: {
        ctaText: tProject('details_page.edit_project'),
        onClick: () => {
          push(`/project/${projectId}/edit`);
        },
      },
      [CTA_STATES_ENUM.PROJECT_GROUP_MANAGER_WITH_MATCHES]: {
        ctaText: tProject('details_page.manage_project'),
        onClick: () => {
          push(`/project/${projectId}/manage`);
        },
      },
      [CTA_STATES_ENUM.PROJECT_FUNDER_NO_FUNDS]: {
        ctaText: tProject('details_page.create_fund'),
        onClick: () => {
          push('/fund/create');
        },
      },
      [CTA_STATES_ENUM.PROJECT_FUNDER_SINGLE_MATCH_MATCHED]: {
        ctaText: tProject('details_page.add_to_shortlist'),
        onClick: () => {
          if (fundId && projectId && updateProjectData) {
            setIsBtnLoading(true);
            createSelection(fundId, projectId)
              .then(() => updateProjectData(projectId))
              .then(() => {
                successNotification(tProject('details_page.added_to_shortlist'));
              })
              .catch(() => {
                errorNotification(tProject('details_page.couldnt_add_to_shortlist'));
              })
              .finally(() => {
                setIsBtnLoading(false);
              });
          } else {
            errorNotification(tProject('details_page.couldnt_add_to_shortlist'));
          }
        },
      },
      [CTA_STATES_ENUM.PROJECT_FUNDER_SINGLE_MATCH_SHORTLISTED]: {
        ctaText: tProject('details_page.offer_funding'),
        onClick: () => {
          if (fundId && selectionId && projectId && updateProjectData) {
            setIsBtnLoading(true);
            fundInvitesProject(fundId, selectionId)
              .then(() => updateProjectData(projectId))
              .then(() => {
                successNotification(tProject('details_page.offered_funding'));
              })
              .catch(() => {
                errorNotification(tProject('details_page.couldnt_offer_funding'));
              })
              .finally(() => {
                setIsBtnLoading(false);
              });
          } else {
            errorNotification(tProject('details_page.couldnt_offer_funding'));
          }
        },
      },
    }),
    [
      fundId,
      push,
      projectId,
      tProject,
      selectionId,
      createSelection,
      updateProjectData,
      errorNotification,
      successNotification,
      fundInvitesProject,
    ],
  );

  const ButtonWithProps = useCallback(
    ({ className, ...props }: ButtonProps) => {
      if (
        ctaState &&
        ctaState !== CTA_STATES_ENUM.PROJECT_GROUP_NONOWNER &&
        ctaState !== CTA_STATES_ENUM.PROJECT_FUNDER_UNMATCHED &&
        ctaState !== CTA_STATES_ENUM.PROJECT_FUNDER_MULTIPLE_MATCH &&
        ctaState !== CTA_STATES_ENUM.PROJECT_UNLOGGED_USER &&
        ctaState !== CTA_STATES_ENUM.PROJECT_FUNDER_SINGLE_MATCH_INVITED
      ) {
        const ctaAttributes: { ctaText: string; onClick: () => void; startIcon?: JSX.Element } =
          ctaState && mapCtaAttributes[ctaState];
        return (
          <Button
            onClick={ctaAttributes.onClick}
            disabled={isBtnLoading}
            loading={isBtnLoading}
            startIcon={ctaAttributes.startIcon}
            aria-label={'project-page-cta-button'}
            {...props}
          >
            {ctaAttributes.ctaText}
          </Button>
        );
      }

      return null;
    },
    [ctaState, mapCtaAttributes, isBtnLoading],
  );

  useEffect(() => {
    setHeaderButtons(
      isBtnInView ? undefined : (
        <ButtonWithProps buttonType={isBtnPrimary ? 'primary' : 'tertiary'} />
      ),
    );

    return () => {
      setHeaderButtons(undefined);
    };
  }, [setHeaderButtons, ButtonWithProps, isBtnInView, isBtnPrimary]);

  return (
    <>
      <ButtonWithProps buttonType={isBtnPrimary ? 'primary' : 'tertiary'} />
    </>
  );
};
