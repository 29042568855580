import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import LocationCityIcon from '@material-ui/icons/LocationCity';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CreateIcon from '@material-ui/icons/Create';

import { OrganizationAvatar, OldTabProps, OldTabs } from '@Components';
import { Button } from '@Components/common';
import { useAppLayoutContext } from '@Contexts';
import { Routes, RoutingPaths } from '@App/paths';
import { useRouter } from '@Helpers/useRouter';

import {
  LinksTile,
  LinksTileProps,
  LocationTile,
  LocationTileProps,
  AboutSection,
  AboutSectionProps,
  FundsListSection,
  FundsListSectionProps,
  ProjectsListSection,
  ProjectsListSectionProps,
  ProjectsFundsSection,
  ProjectsFundsSectionProps,
  ProjectUpdatesSection,
  ProjectUpdatesSectionProps,
} from './Components';
import styles from './ProfilePage.module.scss';

interface ProfileSectionHeaderProps {
  className?: string;
}

export const ProfileSectionHeader: React.FC<ProfileSectionHeaderProps> = ({
  children,
  className,
}) => <h2 className={classnames(styles.sectionHeader, className)}>{children}</h2>;

interface ProfilePageProps {
  isFunder?: boolean;
  isGroup?: boolean;
  name: string;
  avatar?: string;
  currentTab: number;
  setCurrentTab?: (newTab: number) => void;
  tabs: OldTabProps[];
  aboutSection?: AboutSectionProps;
  linksTile?: LinksTileProps;
  locationTile?: LocationTileProps;
  fundsListSection?: FundsListSectionProps;
  projectsFundsList?: ProjectsFundsSectionProps;
  projectsListSection?: ProjectsListSectionProps;
  fundedProjectsSection?: ProjectsListSectionProps;
  projectUpdatesSection?: ProjectUpdatesSectionProps;
  progress: number;
  isCurrentProfile: boolean;
  canModifyOrganizationProfile: boolean;
  totalProjectUpdatesCount?: number;
}

export const ProfilePage: React.FC<ProfilePageProps> = ({
  isFunder,
  name,
  avatar,
  currentTab,
  setCurrentTab,
  tabs,
  aboutSection,
  linksTile,
  locationTile,
  fundsListSection,
  projectsFundsList,
  projectsListSection,
  fundedProjectsSection,
  projectUpdatesSection,
  progress,
  isCurrentProfile,
  canModifyOrganizationProfile,
  totalProjectUpdatesCount,
}) => {
  const { setSubheader } = useAppLayoutContext();
  const { t } = useTranslation('profilePage');
  const { push } = useRouter();

  useEffect(() => {
    setSubheader(
      <div
        className={styles.headerImage}
        style={{ backgroundImage: `url(/assets/ProfilePage/GroupPageSubheader.png)` }}
      >
        <OrganizationAvatar className={styles.avatarWrapper} avatarImage={avatar}>
          <LocationCityIcon style={{ fontSize: '60px' }} />
        </OrganizationAvatar>
      </div>,
    );

    return () => {
      setSubheader(undefined);
    };
  }, [avatar, setSubheader]);

  const handleRedirect = () => {
    push(isFunder ? Routes.FUNDER_PROFILE_EDIT : Routes.GROUP_PROFILE_EDIT);
  };

  return (
    <div>
      <div className={styles.header}>
        <h1 className={styles.profileName}>{name}</h1>
        <div className={styles.profileButtonContainer}>
          {isCurrentProfile && canModifyOrganizationProfile && (
            <div>
              {progress === 100 ? (
                <Button buttonType="tertiary" onClick={handleRedirect} startIcon={<CreateIcon />}>
                  {t('edit')}
                </Button>
              ) : (
                <Button onClick={handleRedirect} buttonType="secondary">
                  {t('complete_profile')}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={styles.columnsLayout}>
        <div className={styles.mainContent}>
          <OldTabs
            tabs={tabs}
            currentTab={currentTab}
            onTabChange={setCurrentTab}
            fullWidth={false}
          />
          {currentTab === 0 && aboutSection && <AboutSection {...aboutSection} />}
          {currentTab === 1 && fundsListSection && <FundsListSection {...fundsListSection} />}
          {currentTab === 1 && projectsListSection && (
            <ProjectsListSection
              title={t('projects_list_header', {
                projectsCount: projectsListSection.projectsPaginatedResponse.totalCount,
              })}
              emptyMessage={t('profilePage:empty_projects_list')}
              {...projectsListSection}
            />
          )}
          {currentTab === 2 && projectsFundsList && <ProjectsFundsSection {...projectsFundsList} />}
          {currentTab === 2 && fundedProjectsSection && (
            <ProjectsListSection
              title={t('funded_projects_header', {
                projectsCount: fundedProjectsSection.projectsPaginatedResponse.totalCount,
              })}
              emptyMessage={t('profilePage:empty_funded_projects_list')}
              {...fundedProjectsSection}
            />
          )}
          {currentTab === 3 && projectUpdatesSection && (
            <ProjectUpdatesSection
              title={t(isFunder ? 'fund_updates_header' : 'group_updates_header', {
                updatesCount: totalProjectUpdatesCount,
              })}
              {...projectUpdatesSection}
            />
          )}
        </div>
        <div className={styles.sideContent}>
          <LinksTile {...linksTile} />
          <LocationTile {...locationTile} />
        </div>
      </div>
    </div>
  );
};
