import { usePaginatedRequest } from '@Hooks/usePaginatedRequest';
import { API } from '@Services';
import { GrandchildrenOptionsEnum, ProjectUpdate } from '@Types';
import { useCallback } from 'react';
import { ReportFilterParameters } from './useGetFunderReportData';

export const useGetFunderImpactProjectUpdates = () => {
  const [fetch, response] = usePaginatedRequest<ProjectUpdate>({ perPage: 10 });

  const getFunderImpactProjectUpdates = useCallback(
    (
      organizationId: number,
      filterParams?: ReportFilterParameters,
      includeGrandchildren?: GrandchildrenOptionsEnum,
      specificGrandchildrenIds?: number[],
    ) => {
      fetch(API.paths.organization_projects_updates(organizationId), {
        include_grandchildren: includeGrandchildren
          ? includeGrandchildren
          : GrandchildrenOptionsEnum.NONE,
        specific_grandchildren_ids: specificGrandchildrenIds ? specificGrandchildrenIds : [],
        ...filterParams,
      });
    },
    [fetch],
  );

  return [getFunderImpactProjectUpdates, response] as const;
};
