import { DrawingMode, GroupTypes, PagedResponse, SDG } from '@Types';

export enum SurveyTypeEnum {
  DEFAULT_SURVEY = 'default_survey',
  SOUTHWEST_WATER = 'southwest_water',
  SHELL = 'shell',
}

export interface BeneficiarySplitStat {
  name: string;
  label: string;
  percentage: number;
  response_count: number;
}

export interface TangibleOutput {
  number: number;
  description: string;
  project_id: number;
  project_name: string;
  organization_name: string;
}

export interface ProjectUpdateData {
  project_id: number;
  update_id: number;
  image_url: string | null;
  video_url: string | null;
  description: string;
}

export interface GivingOverTimeRow {
  month: number;
  year: number;
  total_project_amount: number;
}

export interface RegionBreakdownItem {
  region_id: number;
  region_name: string;
  count: number;
  percentage: number;
}

export interface MultipleChoiceItem {
  name: string;
  label: string;
  count: number;
  percentage: number;
}

export interface QuoteItem {
  project_id: number;
  quote: string;
}

export interface BaseSurveyReportData {
  projects: ProjectData[];
  output_impact_quotes: {
    project_id: number;
    quote: string;
  }[];
  project_update_count: number;
  featured_media_item: { project_id: number; type: string; update_id: number; url: string };
  project_impact_quotes: QuoteItem[];
  organization_types: { type: string; count: number }[];
  project_updates: ProjectUpdateData[];
  media: {
    type: string;
    url: string;
    project_id: number;
  }[];
  video_url: string;
  total_cost: number;
  total_beneficiaries: number;
  goals_achieved_percentage: number;
  total_volunteers: number;
  total_funder_volunteers: number;
  total_funder_volunteer_hours: number;
  sdgs: SDG[];
  focus_areas: {
    name: string;
    value: number;
    percentage: number;
  }[];
  tangible_outputs: TangibleOutput[];
  beneficiary_split_planet: BeneficiarySplitStat[];
  beneficiary_split_people: BeneficiarySplitStat[];
  agreement_question_responses: AgreementQuestionResponse[];
  average_funder_rating: number;
  complete_response_count: number;
  total_people_beneficiaries: number;
  total_planet_beneficiaries: number;
  region_breakdown: RegionBreakdownItem[];
  fund_survey_type: string;
}

export interface FunderSurveyReportData extends BaseSurveyReportData {
  giving_over_time: GivingOverTimeRow[];
}

export interface FundSurveyReportData extends BaseSurveyReportData {
  catchment_area: {
    catchment_area_type: DrawingMode;
    location: number[]; // [latitude, longitude]
    radius: number | null;
    areas: number[][][]; // An array of polygons, each containing an array of points, which are themselves a 2-item array as in 'location'
  };
  matrix_questions: MatrixQuestion[];
  how_did_this_fund_support_your_organisation: MultipleChoiceItem[];
  funding_resulted_in: MultipleChoiceItem[];
  grant_challenges: QuoteItem[];
  unaddressed_challenges: QuoteItem[];
  project_plans: QuoteItem[];
}

export interface FunderSurveyReportSummaryData {
  launched_funds_count: number;
  projects_funded_count: number;
  total_people_supported: number;
  total_funding_distributed: number;
  project_updates_count: number;
}

export interface MatrixQuestion {
  identifier: string;
  label: string;
  questions: {
    label: string;
    answers: {
      [key: string]: {
        percentage: number;
        value: number;
      };
    };
  }[];
  choices: { label: string; identifier: string }[];
}

export interface AgreementQuestionResponse {
  identifier: string;
  percentage: number;
  label: string;
}
export interface ProjectData {
  id: number;
  name: string;
  group_name: string;
  group_type: GroupTypes;
  group_avatar_url?: string;
  url: string;
  image_url: string;
  cost: number;
  latitude: number;
  longitude: number;
}

export type OrganizationGrandchildrenBasicDataApiResponse =
  PagedResponse<OrganizationGrandchildrenBasicData>;

export interface OrganizationGrandchildrenBasicData {
  name: string;
  id: number;
}
